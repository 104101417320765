<template>
  <template v-if="previewModal.isActive">
    <HrdCloudMobilePreview v-if="previewModal.isActive && previewItem" v-model="previewModal" :item="previewItem" />
  </template>
  <template v-else>
    <HrdCloudMobileHeader title="쓸스튜디오" :show-back="true" :go-back-func="closeModal">
      <template v-slot:right>
        <div v-if="!uploadModal.isActive && !pendingModal.isActive" class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="handleNewUpload">
            등록하기
          </a>
        </div>
        <div v-else-if="uploadModal.isActive" class="util util-btn">
          <a href="javascript:" v-if="isTranscoding" class="util-actions util-actions-btn link-secondary">인코딩중</a>
          <a href="javascript:" v-else class="util-actions util-actions-btn text-primary" @click="handleUploadEvent('save')">임시저장</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud-v2">
      <div class="main-content min-component">
        <div v-if="pendingModal.isActive && (previewModal.isActive===false)" class="top-callout">
          <p class="text">등록하신 동영상을 관리자가 심사중입니다.</p>
        </div>
        <article class="content-section">
          <SSLStudioMobileUpload
              v-if="uploadModal.isActive"
              v-model="uploadModal"
              v-model:upload-event="uploadEvent"
              :ctt-mst-sn="cttMstSn"
              :preview-modal="previewModal"
          />
          <SSLStudioMobilePending
              v-else-if="pendingModal.isActive"
              v-model="pendingModal"
              v-model:previewModal="previewModal"
              :ctt-mst-sn="cttMstSn"
          />
          <SSLStudioList
              v-else
              :upload-func="uploadModal"
              :pending-func="pendingModal"
              :is-mobile="true" 
          />
        </article>
        <template v-if="uploadModal.isActive">
          <div class="page-buttons mt-0">
            <button class="kb-btn kb-btn-primary" :disabled="isTranscoding" @click="handleUploadEvent('submit')">{{ isTranscoding ? '인코딩중' : '제출' }}</button>
          </div>
          <div class="page-buttons mt-2">
            <button v-if="checkCttStt(uploadModal.stt)" class="kb-btn kb-btn-secondary" @click="handleDeleteKbTube"><span class="text">삭제</span></button>
          </div>
        </template>
      </div>
    </main>
  </template>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import SSLStudioList from '@/components/ssl/studio/SSLStudioList';
import {reactive, ref} from 'vue';
import SSLStudioMobileUpload from '@/components/ssl/mobile/SSLStudioMobileUpload';
import SSLStudioMobilePending from '@/components/ssl/mobile/SSLStudioMobilePending';
import HrdCloudMobilePreview from '@/components/history/hrdcloud/mobile/HrdCloudMobilePreview';
import {ACT_DELETE_CTT_MST} from '@/store/modules/contents/contents';
import {isSuccessFailAlert} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {checkCttStt} from '@/assets/js/modules/kbtube/kbtube-upload-setup';
import router from "@/router";

export default {
  name: 'SSLMobileStudio',
  components: {HrdCloudMobilePreview, SSLStudioMobilePending, SSLStudioMobileUpload, SSLStudioList, HrdCloudMobileHeader},
  setup() {
    const store = useStore();
    const {showLoading, hideLoading, showConfirm, showMessage} = useAlert();
    const cttMstSn = ref(0);
    const isTranscoding = ref(false); // uploadModal.isActive 일때만

    const setCttMstSn = (sn) => {
      cttMstSn.value = sn;
    };

    const uploadModal = reactive({
      isActive: false,
      stt: '',
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
      update: setCttMstSn,
      toggleSubmitBtn : (val = false) => {
        isTranscoding.value = val;
      },
    });

    const handleNewUpload = () => {
      uploadModal.update(0);
      uploadModal.toggle();
    };

    // 모바일은 업로드 이벤트가 컴포넌트 밖에 있어서 밖에서 처리한다
    const uploadEvent = ref('');
    const handleUploadEvent = (type) => {
      uploadEvent.value = type;
    };

    const pendingModal = reactive({
      isActive: false,
      toggle: () => {
        pendingModal.isActive = !pendingModal.isActive;
      },
      update: setCttMstSn
    });

    const previewItem = ref(null);
    const previewModal = reactive({
      isActive: false,
      toggle: () => {
        previewModal.isActive = !previewModal.isActive;
      },
      update: (item) => {
        previewItem.value = item;
      }
    });

    const closeModal = () => {
      if (uploadModal.isActive) {
        uploadModal.isActive = false;
      } else if (pendingModal.isActive) {
        pendingModal.isActive = false;
      } else {
        router.go(-1);
      }
    };

    const handleDeleteKbTube = () => {
      showConfirm({
        text: '정말 삭제하시겠습니까?<br><p style="color:red;font-weight:bold;">삭제 후 되돌릴 수 없습니다</p>',
        callback: deleteKbTube
      });
    };

    const deleteKbTube = () => {
      showLoading();

      store.dispatch(`contents/${ACT_DELETE_CTT_MST}`, cttMstSn.value).then(res => {
        if (isSuccessFailAlert(res)) {
          showMessage('삭제되었습니다.', closeModal);
        }
      }).catch(e => {
        console.error(e);
      }).finally(hideLoading);
    };

    return {
      cttMstSn, uploadEvent, previewItem,
      uploadModal, pendingModal, previewModal,
      closeModal ,handleNewUpload, handleUploadEvent,
      isTranscoding, checkCttStt, handleDeleteKbTube
    }
  }
};
</script>