<template>
  <div class="kb-form-fields" style="padding: 15px 15px;">
    <div class="kb-form-field field-border-0">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">제목</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ item.cttTitle }}</span>
      </div>
    </div>
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">분야</span></label>
      </div>
      <div class="kb-form-row">
        <span class="kb-form-text">{{ fieldNm }}</span>
      </div>
    </div>
<!--    <div class="kb-form-field">-->
<!--      <div class="kb-form-row">-->
<!--        <label class="kb-form-label"><span class="kb-form-label-text">공개범위</span></label>-->
<!--      </div>-->
<!--      <div class="kb-form-row">-->
<!--        <span class="kb-form-text">{{ expsrServ }}</span>-->
<!--      </div>-->
<!--    </div>-->
    <div class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">영상/PDF 업로드</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ item.fileNm }}</p>
          <div class="close">
            <span v-if="item.cttFileSz > 0" class="close-text">{{ convertToStorageBytes(item.cttFileSz) }}</span>
          </div>
        </div>
      </div>
      <div class="kb-form-row row-buttons">
        <button v-if="item.buketNm.indexOf('contents') !== -1 || item.cttTyCdDcd === '2034005' " class="kb-btn kb-btn-outline kb-btn-content-item" @click="showPreview"><span class="text">미리보기</span></button>
        <button v-else class="kb-btn kb-btn-outline kb-btn-content-item"><span class="text">인코딩중...</span></button>
      </div>
    </div>
    <div v-if="item.thumb" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">썸네일 업로드</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <article class="hrdcloud-card" style="width: 104px;">
            <div class="hrdcloud-image">
              <div class="image" style="height: 184px;">
                <a href="javascript:" class="image-link">
                  <img :src="item.thumb" alt="썸네일 이미지" style="width: 104px;"/>
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.orgFileNm)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">첨부파일</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <p class="text">{{ item.orgFileNm }}</p>
          <div class="close">
            <span v-if="item.fileSz > 0" class="close-text">{{ convertToStorageBytes(item.fileSz) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.cttCn)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">설명</span></label>
      </div>
      <div class="kb-form-row">
        <div class="editor-content m-h-300 ms-0">
          <div ref="pageEditor"></div>
        </div>
      </div>
    </div>
    <div v-if="stringCheck(item.tags)" class="kb-form-field">
      <div class="kb-form-row">
        <label class="kb-form-label"><span class="kb-form-label-text">태그</span></label>
      </div>
      <div class="kb-form-row">
        <div class="file-text">
          <div class="tags-wrap">
            <div class="tag-list">
              <div v-for="(hashTag, idx) in item.tags" class="tag-item is-active" :key="idx">
                <span class="tag-item-text">{{ hashTag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kb-form-fields-bottom divider" style="height: unset !important; padding: 15px 15px;">
    <strong class="text">유의사항</strong>
    <p class="text text-icon">1. 제작한 영상/PDF를 <span style="color:#ff8000;line-height:unset !important;">MP4(최대 2GB)/PDF파일(최대 20MB)로 암호화 해제 후 외부망에서 업로드</span> 해주세요.</p>
    <!--    <p class="text">2. 첨부파일(붙임자료)가 있는 경우 <span style="color:#ff8000;line-height:unset !important;">암호화 해제 후 업로드</span> 해 주세요.</p>-->
    <!--    <p class="text"> ㅇ 암호화된 파일은 외부망에서 열람 불가</p>-->
    <!--    <p class="text"> ㅇ PDF/PPT/EXCEL 파일 등록 가능(최대 10MB)</p>-->
    <p class="text">2. <span style="color:#ff8000;line-height:unset !important;">
                콘텐츠에 개인정보 및 중요정보가 포함되지 않도록 유의</span>하여 주시고, 개인정보 유출 등으로 인한 책임은 등록자에게 있음을 확인해주시기 바랍니다.
    </p>
  </div>
</template>

<script>
import {convertToStorageBytes, getImg, getItems, lengthCheck, setParams, stringCheck} from '@/assets/js/util';
import {getSSLStudioList} from '@/assets/js/modules/hrd/hrd-history-common';
import {computed, onMounted, reactive, ref, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import ClassicEditor from "@/assets/js/editor/custom-ckeditor";
import {kbtubeEditorConfig} from "@/assets/js/editor/editor-config";
import {currentYear, getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {ACT_GET_MY_SSL_LST} from "@/store/modules/ssl/ssl-index";

export default {
  name: 'SSLStudioMobilePending',
  props: {
    modelValue: Object,
    cttMstSn: Number,
    previewModal: Object
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const {showLoading, hideLoading} = useAlert();
    const {showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);
    const fieldNm = ref('');

    const expsrServ = computed(() => {
      // if (item.expsrServ === '2053001,2053002,2053003,2053004')
      return '전직원';
    })

    const item = reactive({
      cttTitle: '',
      cloudNmLst: '',
      expsrServ: '',
      fileNm: '',
      etnUrl: '',
      itnUrl: '',
      orgFileNm: '',
      cttFileSz: 0,
      fileSz: 0,
      buketNm: '',
      thumb: '',
      tag: '',
      cttCn: '',
      tags: [],
      lrnTrgtGrpMstSn: '',
      refTyNm: '',
      cttTyCdDcd: ''
    });

    const pageEditor = ref(null);
    let $_instance = null;

    const distOptions =ref([]);
    const getOptions= async ()=>{ //년도별 my입과 과정
      // console.log('year : ' + filters.year);
      distOptions.value = [];

      await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, currentYear).then(res => {
        if (lengthCheck(res)) {
          distOptions.value = getItems(res);
        }
        distOptions.value = getItems(res).map(x=>({...x, distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)}));
        console.log("distOptions.value = ", distOptions.value);

      }).catch(e => {
        console.error(e);
      });
    }

    const showPreview = () => {
      if (session.value.itn && item.itnUrl) {
        props.previewModal.update(item);
        props.previewModal.toggle();
      } else if (!session.value.itn && item.etnUrl) {
        props.previewModal.update(item);
        props.previewModal.toggle();
      } else {
        showMessage('현재 망에서 재생할 수 있는 영상이 없습니다.');
      }
    };

    let tryCount = 0;
    const setPageEditor = () => {
      if(tryCount > 10) return;
      if(pageEditor.value){
        ClassicEditor.create( pageEditor.value, kbtubeEditorConfig ).then(editor => {
          editor.isReadOnly = true;
          $_instance = editor;
          if(item.cttCn) $_instance.setData(item.cttCn);
        }).catch(error => {
          console.error(error);
        })
      }else{
        tryCount++;
        setPageEditor();
      }
    }

    onMounted(async () => {
      await getOptions();
      showLoading();
      getSSLStudioList({cttMstSn: cttMstSn.value}, null, {}, async (res) => {
        await setParams(item, res.items[0]);
        if (item.cloudNmLst && item.cloudNmLst.length > 0) {
          item.cloudNmLst = item.cloudNmLst.replaceAll(/,/g, ' > ');
        }
        item.thumb = stringCheck(item.thumb) ? getImg(item.thumb) : null;
        item.tags = stringCheck(item.tag) ? item.tag.split(',') : [];

        if (item.lrnTrgtGrpMstSn) {
          let crseMstSn;
          if (item.refTyNm) {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == Number(item.refTyNm)).map(y => y.crseMstSn);
          } else {
            crseMstSn = distOptions.value.filter(x => x.lrnTrgtGrpMstSn == item.lrnTrgtGrpMstSn).map(y => y.crseMstSn);
          }

          fieldNm.value = distOptions.value.find(x => x.crseMstSn == crseMstSn).crseNm;
        }

        await setPageEditor();
        hideLoading();
      },() => {hideLoading()});
    });

    return {
      item, expsrServ, pageEditor, showPreview,
      convertToStorageBytes, stringCheck,fieldNm
    }
  }
};
</script>